import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { useLoginStore } from '../store/login';

// 페이지 컴포넌트 임포트
import LoginView from "@/views/LoginView.vue";
import JoinView from "@/views/JoinView.vue";
import MainView from "@/views/MainView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/join',
    name: 'join',
    component: JoinView,
  },
  {
    path: '/',
    name: 'main',
    component: MainView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const loginStore = useLoginStore();
  const accessToken = loginStore.getAccessToken;

  if (!accessToken) {
    // 토큰이 없는 경우, 메인 페이지로 이동하려 하면 로그인 페이지로 리다이렉트
    if (to.name === 'main') {
      next({ name: 'login' });
    } else {
      next(); // 로그인이나 가입 페이지는 접근 허용
    }
  } else {
    // 토큰이 있는 경우, 로그인 페이지에 접근 시 메인 페이지로 리다이렉트
    if (to.name === 'login') {
      next({ name: 'main' });
    } else {
      next(); // 다른 페이지는 그대로 진행
    }
  }
});

export default router;
