import { defineStore } from 'pinia';
import { recordLog } from '../api';

// 상태 인터페이스 정의
interface LogState {
  startTime: Date | null;
  endTime: Date | null;
  readingTime: number | null;
  message: string;
}

// Pinia 스토어 정의
export const useLogStore = defineStore('log', {
  state: (): LogState => ({
    startTime: null, // Date 객체 또는 null로 초기화
    endTime: null,
    readingTime: 0,
    message: ''
  }),
  actions: {
    initTime(): void {
      this.startTime = null; // null로 초기화
      this.endTime = null;
    },
    startTask(): void {
      this.startTime = new Date(); // Date 객체 사용
    },
    endTask(log: any): void {
      this.endTime = new Date(); // Date 객체 사용
      this.message = log;
      this.saveReadingTime();
    },
    calculateReadingTime(): number | null {
      if (!this.startTime || !this.endTime) {
        console.error("start_time 또는 end_time이 설정되지 않았습니다.");
        return null;
      }

      // 밀리초 단위 차이를 초 단위로 변환
      const durationInSeconds = parseFloat(((this.endTime.getTime() - this.startTime.getTime()) / 1000).toFixed(2));
      return durationInSeconds;
    },
    async saveReadingTime(): Promise<void> {
      this.readingTime = this.calculateReadingTime();
      
      if (this.readingTime !== null && this.startTime && this.endTime) {
        const kstStartDate = new Date(this.startTime).getTimezoneOffset() * 60000;
        const kstEndDate = new Date(this.endTime).getTimezoneOffset() * 60000;
        const utcStartDate = new Date(this.startTime.getTime() - kstStartDate);
        const utcEndDate = new Date(this.endTime.getTime() - kstEndDate);

        try {
          // API 호출 및 로그 기록
          await recordLog(utcStartDate, utcEndDate, this.readingTime, this.message);
          // this.initTime();
        } catch (error) {
          console.error("Error saving reading time:", error);
        }
      }
    },
  },
  getters: {
    formattedStartTime: (state): string => {
      return state.startTime ? state.startTime.toLocaleString() : '시작 시간 없음';
    },
    formattedEndTime: (state): string => {
      return state.endTime ? state.endTime.toLocaleString() : '종료 시간 없음';
    },
    getReadingTime(state): number | null {
      return state.readingTime;
    }
  },
});
