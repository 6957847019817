
import { defineComponent, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useLoginStore } from '../../store/login'
import { useUsersStore } from '../../store/users'
import { usePopupStore } from '@/store/popup';
import { initializeUserData } from '@/api';

export default defineComponent({
  components: {
  },
  setup() {
    const router = useRouter();
    const loginUser = computed(() => useUsersStore().getLoginUser);

    const inputId = ref('');
    const inputPassword = ref('');
    const incorrectError = ref(false);
    const idError = ref(false);
    const pwError = ref(false);
    const alreadyLoginError = ref(false);
    const loginCountError = ref(false);
    
    const login = async (): Promise<void> => {
      if(inputId.value == '') {
        idError.value = true;
      } else if(inputPassword.value == '') {
        pwError.value = true;
      } else {
        const res = await useLoginStore().login(inputId.value, inputPassword.value);
        if(res?.state) {
          router.push({'name': 'main'});
          await useUsersStore().getUserData();
          await initializeUserData(); // 로그인할 때마다 전역변수 초기화
        } else {
          if (res?.data == 'Already login') {
            alreadyLoginError.value = true;
          } else if (res?.data == 'login count over') {
            loginCountError.value = true;
          } else {
            incorrectError.value = true;
          }
        }
      }
    }

    const hideErrorMsg = () => {
      idError.value = false;
      pwError.value = false;
      incorrectError.value = false;
      alreadyLoginError.value = false;
      loginCountError.value = false;
    }

    return {
      login,
      inputId,
      inputPassword,
      incorrectError,
      idError,
      pwError,
      loginUser,
      hideErrorMsg,
      alreadyLoginError,
      loginCountError
    };
  },
});
