import axios, { AxiosProgressEvent, AxiosError } from 'axios';
import { ref } from 'vue';
import { useLoginStore } from './store/login';
import { useUsersStore, JoinUser } from './store/users';
import { useDicomStore } from './store/dicom';
import { useLogStore } from './store/log';
import { useTimerStore } from './store/timer';
import { useReadingResultStore } from './store/readingResult';

export const PROJECT_TYPE = "TDM";
// export const LOGIN_SERVER_URL = 'http://10.10.20.220/auth';
// export const NGTUBE_SERVER_URL = 'http://10.10.20.233:8000'; 

// 10.10.20.239:80

 export const LOGIN_SERVER_URL = '/auth'; // nginx
 export const NGTUBE_SERVER_URL = '/api';

/**
 * 언어 데이터를 가져온다.
 * @returns {string} 언어 데이터
 */
// export async function getLanguageData() {
//   try {
//     const response = await axios.get(`${API_BASE_URL}/api/get/language`, {
//     });
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching users:', error);
//     return [];
//   }
// }

/**
 * LOGIN SERVER - login
 */
export async function login(userId: string, passwd: string) {
  try {
    const response = await axios.post(`${LOGIN_SERVER_URL}/login`, {
      "user_id": userId,
      "passwd": passwd,
      "login_type": PROJECT_TYPE
    });
    if(response.data?.result == "Already login") {
      return { state: false, data: response.data.result }
    }
    return { state: true, data: response.data }
  } catch (error: any) {
    console.error('Error fetching posts:', error);
    return { state: false, data: error.response.data.detail }
  }
}

/**
 * LOGIN SERVER - logout
 */
export async function logout() {
  try {
    const token = useLoginStore().getAccessToken;
    const userId = useUsersStore().getLoginUser.data.user_id;

    const response = await axios.post(`${LOGIN_SERVER_URL}/logout`, {
      "user_id": userId,
      "passwd": sessionStorage.getItem('user_passwd'),
      "login_type": PROJECT_TYPE
    },{ 
      headers: {
        Authorization: `Bearer ${token}`
      }
    },);

    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - getAllUserData
 */
export async function getAllUserData() {
  const token = useLoginStore().getAccessToken;
  try {
    const response = await axios.get(`${LOGIN_SERVER_URL}/getAllUserData`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    return [];
  }
}

/**
 * LOGIN SERVER - getUserData
 */
export async function getUserData() {
  try {
    const token = useLoginStore().getAccessToken;
    const response = await axios.get(`${LOGIN_SERVER_URL}/getUserData`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - setUserData
 */
export async function setUserData(userData: JoinUser) {
  try {
    const data = userData.data;
    const response = await axios.post(`${LOGIN_SERVER_URL}/setUserData`, {
      "user_id": data.user_id,
      "passwd": userData.passwd,
      "group": {
        "group_id": data.group.group_id,
        "level": data.group.level,
        "name": data.group.name
      },
      "name": data.name,
      "number": data.number,
      "email": data.email,
      "division": data.division
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - checkRepetition
 */
export async function checkRepetition(inputId: string) {
  try {
    const response = await axios.get(`${LOGIN_SERVER_URL}/checkRepetition`, {
      params: {
        "user_id": inputId
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - removeUserData
 */
export async function removeUserData(userData: JoinUser) {
  try {
    const token = useLoginStore().getAccessToken;
    const data = userData.data;
    const response = await axios.post(`${LOGIN_SERVER_URL}/removeUserData`, {
      "user_id": data.user_id,
      "passwd": userData.passwd,
      "group": {
        "group_id": data.group.group_id,
        "level": data.group.level,
        "name": data.group.name
      },
      "name": data.name,
      "number": data.number,
      "email": data.email,
      "division": data.division
    },{ 
      headers: {
        Authorization: `Bearer ${token}`
      }
    },);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - updateUserData
 */
export async function updateUserData(userData: JoinUser) {
  try {
    const token = useLoginStore().getAccessToken;
    const data = userData.data;
    const response = await axios.post(`${LOGIN_SERVER_URL}/updateUserData`, {
      "user_id": data.user_id,
      "passwd": userData.passwd,
      "group": {
        "group_id": data.group.group_id,
        "level": data.group.level,
        "name": data.group.name
      },
      "name": data.name,
      "number": data.number,
      "email": data.email,
      "division": data.division
    },{ 
      headers: {
        Authorization: `Bearer ${token}`
      }
    },);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - getGroups
 */
export async function getGroups() {
  try {
    const token = useLoginStore().getAccessToken;
    const response = await axios.get(`${LOGIN_SERVER_URL}/getGroups`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - addGroups
 */
export async function addGroups(groupData: any) {
  try {
    const token = useLoginStore().getAccessToken;
    const response = await axios.post(`${LOGIN_SERVER_URL}/addGroups`, {
      "group_id": 0,
      "level": 0,
      "name": "string"
    },{ 
      headers: {
        Authorization: `Bearer ${token}`
      }
    },);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - deleteGroups
 */
export async function deleteGroups(groupData: any) {
  try {
    const token = useLoginStore().getAccessToken;
    const response = await axios.post(`${LOGIN_SERVER_URL}/deleteGroups`, {
      "group_id": 0,
      "level": 0,
      "name": "string"
    },{ 
      headers: {
        Authorization: `Bearer ${token}`
      }
    },);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - updateGroups
 * @param group_id PK. group_id를 기준으로 level, name이 업데이트 됨
 */
export async function updateGroups(groupData: any) {
  try {
    const token = useLoginStore().getAccessToken;
    const response = await axios.post(`${LOGIN_SERVER_URL}/updateGroups`, {
      "group_id": 0, 
      "level": 0,
      "name": "string"
    },{ 
      headers: {
        Authorization: `Bearer ${token}`
      }
    },);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - getSystemLogs
 */
export async function getSystemLogs() {
  try {
    const token = useLoginStore().getAccessToken;
    const response = await axios.get(`${LOGIN_SERVER_URL}/getSystemLogs`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - changePasswd
 * @param user_id 사용자 ID
 * 관리자 계정으로 접속 시 사용할 수 있는 기능
 * 사용자의 비밀번호를 ID와 동일한 값으로 변경한다.
 */
export async function changePasswd(groupData: any) {
  try {
    const token = useLoginStore().getAccessToken;
    const response = await axios.post(`${LOGIN_SERVER_URL}/changePasswd`, {
      "user_id": sessionStorage.getItem('user_id')
    },{ 
      headers: {
        Authorization: `Bearer ${token}`
      }
    },);
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - checkTokenExpire
 */
export async function checkTokenExpire() {
  try {
    const token = useLoginStore().getAccessToken;
    const response = await axios.get(`${LOGIN_SERVER_URL}/checkTokenExpire`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * LOGIN SERVER - recreateToken
 */
export async function recreateToken() {
  try {
    const response = await axios.get(`${LOGIN_SERVER_URL}/recreateToken`, {
      params: {
        "user_id": "string",
        "passwd": "string",
        "login_type": PROJECT_TYPE
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

/**
 * NGTUBE SERVER - initializeUserData
 */
export async function initializeUserData() {
  const userId = useUsersStore().getLoginUser.data.user_id;
  
  try {
    useLoginStore().resetLogoutTimer();
    useLogStore().startTask();
    await axios.post(`${NGTUBE_SERVER_URL}/initialize_user_data`, {
        "user_id": userId,
      },{
    });
    useLogStore().endTask('initialize user data 기능이 성공적으로 실행되었습니다.');
  } catch (error) {
    if (error instanceof AxiosError) {
      const statusCode = error.response?.status;  // HTTP 상태 코드
      const detailMessage = error.response?.data.detail[0].msg;  // FastAPI의 상세 메시지
      const errorType = error.response?.data.detail[0].type;  // 에러 타입
      useLogStore().endTask(`initialize user data 기능 중 오류 발생 (Status: ${statusCode}, 
      Detail: ${detailMessage}, Type: ${errorType})`);
      console.error(`Error Status: ${statusCode}, Detail: ${detailMessage}, Type: ${errorType}`);
    } else {
      // 예상치 못한 에러인 경우
      useLogStore().endTask('initialize user data 기능 사용 중 오류가 발생하였습니다.');
      console.error("Unexpected Error:", error);
    }
  }
}

/**
 * NGTUBE SERVER - dicomUpload
 */
export async function dicomUpload(uploadProgressCallback: (progress: number) => void) {
  const formData = new FormData();
  const dicomFile = useDicomStore().getFile; // Pinia 스토어에서 파일 가져오기
  const userId = useUsersStore().getLoginUser.data.user_id;

  if (!dicomFile || dicomFile.length === 0 || !(dicomFile[0] instanceof File)) {
    console.error("No file selected or invalid file.");
    return;
  }
  formData.append('file', dicomFile[0]);
  formData.append('user_id', userId);

  try {
    useLoginStore().resetLogoutTimer();
    useLogStore().startTask();
    const response = await axios.post(`${NGTUBE_SERVER_URL}/dicom_upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'arraybuffer',
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        if (progressEvent.total) {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          uploadProgressCallback(progress);
        }
      },
    });
    useLogStore().endTask('dicom upload 기능이 성공적으로 실행되었습니다.');

    const window_center_min = parseFloat(response.headers['window_center_min']);
    const window_center_max = parseFloat(response.headers['window_center_max']);
    const window_width_min = parseFloat(response.headers['window_width_min']);
    const window_width_max = parseFloat(response.headers['window_width_max']);
    const window_center = parseFloat(response.headers['window_center']);
    const window_width = parseFloat(response.headers['window_width']);
    const blob = new Blob([response.data], { type: 'image/png' });
    const imageUrl = URL.createObjectURL(blob);

    return { blob, imageUrl, window_center_min, window_center_max, window_width_min, window_width_max, window_center, window_width };
  } catch (error) {
    if (error instanceof AxiosError) {
      const statusCode = error.response?.status;  // HTTP 상태 코드
      const detailMessage = error.response?.data.detail[0].msg;  // FastAPI의 상세 메시지
      const errorType = error.response?.data.detail[0].type;  // 에러 타입
      useLogStore().endTask(`dicom upload 기능 중 오류 발생 (Status: ${statusCode}, 
      Detail: ${detailMessage}, Type: ${errorType})`);
      console.error(`Error Status: ${statusCode}, Detail: ${detailMessage}, Type: ${errorType}`);
    } else {
      // 예상치 못한 에러인 경우
      useLogStore().endTask('dicom upload 기능 사용 중 오류가 발생하였습니다.');
      console.error("Unexpected Error:", error);
    }
  }
}

/**
 * NGTUBE SERVER - prediction
 */
export async function prediction() {
  const dicomFile = useDicomStore().getFile;
  const userId = useUsersStore().getLoginUser.data.user_id;

  try {
    useLoginStore().resetLogoutTimer();
    useLogStore().startTask();
    const response = await axios.post(`${NGTUBE_SERVER_URL}/prediction`, {
      file_name: dicomFile[0].name,
      user_id: userId
    },{
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const imageUrl = "";
    const complete_score = parseFloat(response.data['complete_score']);
    const image_weight = parseFloat(response.data['image_weight']);
    const incomplete_score = parseFloat(response.data['incomplete_score']);
    const tip_threshold = parseFloat(response.data['tip_threshold']);
    const tube_threshold = parseFloat(response.data['tube_threshold']);
    const select_heatmap_idx = parseFloat(response.data['select_heatmap_idx']);
    
    useLogStore().endTask('prediction 기능이 성공적으로 실행되었습니다.');

    return { imageUrl, complete_score, image_weight, incomplete_score, tip_threshold, tube_threshold, select_heatmap_idx };
  } catch (error) {
    if (error instanceof AxiosError) {
      const statusCode = error.response?.status;  // HTTP 상태 코드
      const detailMessage = error.response?.data.detail[0].msg;  // FastAPI의 상세 메시지
      const errorType = error.response?.data.detail[0].type;  // 에러 타입
      useLogStore().endTask(`prediction 기능 중 오류 발생 (Status: ${statusCode}, 
      Detail: ${detailMessage}, Type: ${errorType})`);
      console.error(`Error Status: ${statusCode}, Detail: ${detailMessage}, Type: ${errorType}`);
    } else {
      // 예상치 못한 에러인 경우
      useLogStore().endTask('prediction 기능 사용 중 오류가 발생하였습니다.');
      console.error("Unexpected Error:", error);
    }
  }
}

/**
 * NGTUBE SERVER - originVisualization
 */
export async function originVisualization(option: any) {
  try {
    useLoginStore().resetLogoutTimer();
    useLogStore().startTask();
    const response = await axios.post(`${NGTUBE_SERVER_URL}/origin_visualization`, option, {
      responseType: 'arraybuffer',
    });
    useLogStore().endTask('window setting 기능이 성공적으로 실행되었습니다.');

    const blob = new Blob([response.data], { type: 'image/png' });
    const imageUrl = URL.createObjectURL(blob);

    return { blob, imageUrl };
  } catch (error) {
    if (error instanceof AxiosError) {
      const statusCode = error.response?.status;  // HTTP 상태 코드
      const detailMessage = error.response?.data.detail[0].msg;  // FastAPI의 상세 메시지
      const errorType = error.response?.data.detail[0].type;  // 에러 타입
      useLogStore().endTask(`window setting 기능 중 오류 발생 (Status: ${statusCode}, 
      Detail: ${detailMessage}, Type: ${errorType})`);
      console.error(`Error Status: ${statusCode}, Detail: ${detailMessage}, Type: ${errorType}`);
    } else {
      // 예상치 못한 에러인 경우
      useLogStore().endTask('window setting 기능 사용 중 오류가 발생하였습니다.');
      console.error("Unexpected Error:", error);
    }
  }
}

/**
 * NGTUBE SERVER - applyVisualizationOptions
 */
export async function applyVisualizationOptions(option: any) {
  try {
    useLoginStore().resetLogoutTimer();
    useLogStore().startTask();
    const response = await axios.post(`${NGTUBE_SERVER_URL}/apply_visualization_options`, option, {
      responseType: 'arraybuffer',
    });
    const checks = ["mask_check", "heatmap_check", "probability_map_check"];
    // const input = ref('');

    // 모든 체크 조건을 확인
    // for (const check of checks) {
    //   if(input.value != '') {
    //     input.value += ', '
    //   }
    //   if(option[check]) {
    //     if(check == "mask_check") {
    //       input.value += check.split("_")[0] + ": true, tip_threshold: " + option.tip_threshold
    //       + ", tube_threshold: " + option.tube_threshold
    //     } else if (check == "heatmap_check") {
    //       input.value += check.split("_")[0] + ": true, heatmap_option: " + option.heatmap_option
    //       + ", image_weight: " + option.image_weight
    //     }
    //   }
    // }
    // input.value = "{ " + input.value + " }"

    const applyOptions = ref('');

    for (const check of checks) {
      if(option[check]) {
        if(applyOptions.value != '') { applyOptions.value += ", " }
        applyOptions.value += check.split("_")[0]
      }
    }
    useLogStore().endTask('apply visualization options - ' + applyOptions.value + ' 기능이 성공적으로 실행되었습니다.');
    
    const blob = new Blob([response.data], { type: 'image/png' });
    const imageUrl = URL.createObjectURL(blob);

    return { blob, imageUrl };
  } catch (error) {
    if (error instanceof AxiosError) {
      const statusCode = error.response?.status;  // HTTP 상태 코드
      const detailMessage = error.response?.data.detail[0].msg;  // FastAPI의 상세 메시지
      const errorType = error.response?.data.detail[0].type;  // 에러 타입
      useLogStore().endTask(`apply visualization options 기능 중 오류 발생 (Status: ${statusCode}, 
      Detail: ${detailMessage}, Type: ${errorType})`);
      console.error(`Error Status: ${statusCode}, Detail: ${detailMessage}, Type: ${errorType}`);
    } else {
      // 예상치 못한 에러인 경우
      useLogStore().endTask('apply visualization options 기능 사용 중 오류가 발생하였습니다.');
      console.error("Unexpected Error:", error);
    }
  }
}

/**
 * NGTUBE SERVER - export
 */
export async function exportFile(target: string, format: string) {
  try {
    useLoginStore().resetLogoutTimer();
    useLogStore().startTask();
    const formData = new FormData();
    const dicomFile = useDicomStore().getFile;
    const dicomInfo = useDicomStore().getDicomInfo;
    const predictionData = useDicomStore().getPredictionData;

    if(target == 'origin') {
      if (dicomInfo?.blob) {
        formData.append('file_name', dicomFile[0].name);
        formData.append('image', dicomInfo?.blob);
      } else {
        console.error('blob is null and cannot be added to FormData.');
        return;
      }
    }
    
    if(target == 'prediction') {
      if (predictionData?.blob) {
        formData.append('file_name', dicomFile[0].name);
        formData.append('image', predictionData?.blob);
      } else {
        console.error('blob is null and cannot be added to FormData.');
        return;
      }
    }

    formData.append('export_format', format);

    // axios 요청 시 responseType을 'blob'으로 설정하여 바이너리 데이터를 받음
    const response = await axios.post(`${NGTUBE_SERVER_URL}/export`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob',  // 파일을 blob 형태로 받기
    });

    // 서버에서 반환된 Blob 데이터를 사용해 파일 다운로드
    const downloadUrl = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = downloadUrl;

    // 다운로드할 파일 이름을 설정
    link.download = `exported_file.${format}`; // 서버에서 받은 파일명으로 설정 가능
    link.click(); // 다운로드 시작

    // 다운로드 후 URL 객체를 해제
    URL.revokeObjectURL(downloadUrl);

    useLogStore().endTask('export file 기능이 성공적으로 실행되었습니다.');
    
  } catch (error) {
    if (error instanceof AxiosError) {
      const statusCode = error.response?.status;  // HTTP 상태 코드
      const detailMessage = error.response?.data.detail[0].msg;  // FastAPI의 상세 메시지
      const errorType = error.response?.data.detail[0].type;  // 에러 타입
      useLogStore().endTask(`export file 기능 중 오류 발생 (Status: ${statusCode}, 
      Detail: ${detailMessage}, Type: ${errorType})`);
      console.error(`Error Status: ${statusCode}, Detail: ${detailMessage}, Type: ${errorType}`);
    } else {
      // 예상치 못한 에러인 경우
      useLogStore().endTask('export file 기능 사용 중 오류가 발생하였습니다.');
      console.error("Unexpected Error:", error);
    }
  }
}

/**
 * NGTUBE SERVER - insertReadingResult
 */
export async function insertReadingResult() {
  const userId = useUsersStore().getLoginUser.data.user_id;
  const withCAD = useUsersStore().getWithCAD;
  const dicomFile = useDicomStore().getFile;
  const readingTime = useTimerStore().getReadingTime;
  const completeScore = useDicomStore().getCompleteScore;

  const param = {
    unique_id: 0,
    user_id: userId,
    check_pred: withCAD,
    file_name: dicomFile[0].name,
    complete_score: completeScore,
    reading_time: readingTime
  }

  try {
    useLoginStore().resetLogoutTimer();
    useLogStore().startTask();
    const response = await axios.post(`${NGTUBE_SERVER_URL}/insert_reading_result`, param, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    useLogStore().endTask('insert reading result 기능이 성공적으로 실행되었습니다.');
    recordLog(useTimerStore().getStartTime, useTimerStore().getEndTime, useTimerStore().getReadingTime, '소요 시간: ' + useTimerStore().getReadingTime + 's')
    useReadingResultStore().setReadingResultList();
  } catch (error) {
    if (error instanceof AxiosError) {
      const statusCode = error.response?.status;  // HTTP 상태 코드
      const detailMessage = error.response?.data.detail[0].msg;  // FastAPI의 상세 메시지
      const errorType = error.response?.data.detail[0].type;  // 에러 타입
      useLogStore().endTask(`insert reading result 기능 중 오류 발생 (Status: ${statusCode}, 
      Detail: ${detailMessage}, Type: ${errorType})`);
      console.error(`Error Status: ${statusCode}, Detail: ${detailMessage}, Type: ${errorType}`);
    } else {
      // 예상치 못한 에러인 경우
      useLogStore().endTask('insert reading result 기능 사용 중 오류가 발생하였습니다.');
      console.error("Unexpected Error:", error);
    }
  }
}

/**
 * NGTUBE SERVER - getReadingResult
 */
export async function getReadingResult() {
  const userId = useUsersStore().getLoginUser.data.user_id;
  const withCAD = useUsersStore().getWithCAD;

  try {
    useLoginStore().resetLogoutTimer();
    useLogStore().startTask();
    const response = await axios.get(`${NGTUBE_SERVER_URL}/get_reading_result`, {
      params: {
        user_id: userId,
        check_pred: withCAD,
      },
    });
    useLogStore().endTask('get reading result 기능이 성공적으로 실행되었습니다.');
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      const statusCode = error.response?.status;  // HTTP 상태 코드
      const detailMessage = error.response?.data.detail[0].msg;  // FastAPI의 상세 메시지
      const errorType = error.response?.data.detail[0].type;  // 에러 타입
      useLogStore().endTask(`get reading result 기능 중 오류 발생 (Status: ${statusCode}, 
      Detail: ${detailMessage}, Type: ${errorType})`);
      console.error(`Error Status: ${statusCode}, Detail: ${detailMessage}, Type: ${errorType}`);
    } else {
      // 예상치 못한 에러인 경우
      useLogStore().endTask('get reading result 기능 사용 중 오류가 발생하였습니다.');
      console.error("Unexpected Error:", error);
    }
    return [];
  }
}

/**
 * NGTUBE SERVER - deleteReadingResult
 */
export async function deleteReadingResult() {
  const readingResultList = useReadingResultStore().getReadingResultList;

  const targetListId = ref<any>([]);

  for (let i = 0; i < readingResultList.length; i++) {
    if (readingResultList[i].selected) {
      targetListId.value = [...targetListId.value, readingResultList[i].unique_id];
    }
  }

  try {
    useLoginStore().resetLogoutTimer();
    useLogStore().startTask();
    const response = await axios.delete(`${NGTUBE_SERVER_URL}/delete_reading_result`, {
      data: {
        unique_ids: targetListId.value,
      },
    });
    useLogStore().endTask('delete reading result 기능이 성공적으로 실행되었습니다.');
    if(response.status == 200) {
      return true;
    }
    return false;
  } catch (error: any) {
    if (error instanceof AxiosError) {
      const statusCode = error.response?.status;  // HTTP 상태 코드
      const detailMessage = error.response?.data.detail[0].msg;  // FastAPI의 상세 메시지
      const errorType = error.response?.data.detail[0].type;  // 에러 타입
      useLogStore().endTask(`delete reading result 기능 중 오류 발생 (Status: ${statusCode}, 
      Detail: ${detailMessage}, Type: ${errorType})`);
      console.error(`Error Status: ${statusCode}, Detail: ${detailMessage}, Type: ${errorType}`);
    } else {
      // 예상치 못한 에러인 경우
      useLogStore().endTask('delete reading result 기능 사용 중 오류가 발생하였습니다.');
      console.error("Unexpected Error:", error);
    }
    return false;
  }
}

/**
 * NGTUBE SERVER - logs
 */
export async function recordLog(startTime: any, endTime: any, readingTime: number, message: any) {
  const userId = useUsersStore().getLoginUser.data.user_id;
  const dicomFile = useDicomStore().getFile;
  const withCAD = useUsersStore().getWithCAD;
  const cadStatus = ref('');
  const fileName = ref('');

  if(dicomFile.length > 0) {
    fileName.value = dicomFile[0].name;
  } 

  if(withCAD) {
    cadStatus.value = 'withCAD';
  } else {
    cadStatus.value = 'withoutCAD';
  }

  const param = {
    user_id: userId,
    file_name: fileName.value,
    message: message,
    start_time: startTime,
    end_time: endTime,
    cad_status: cadStatus.value,
    elapsed_time: readingTime
  }

  try {
    const response = await axios.post(`${NGTUBE_SERVER_URL}/logs`, param, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}