import { defineStore } from 'pinia';
import { getReadingResult, deleteReadingResult } from '../api';
import _ from 'lodash';

// 상태 인터페이스 정의
interface ReadingResultState {
  readingResultList: any[];
  showNewIcon: boolean;
}

// Pinia 스토어 정의
export const useReadingResultStore = defineStore('reading', {
  state: (): ReadingResultState => ({
    readingResultList: [],
    showNewIcon: false
  }),
  actions: {
    async setReadingResultList() {
      const res = await getReadingResult();
      this.readingResultList = [];
      if(res.length > 0) {
        for(let i=0; i<res.length; i++) {
          this.readingResultList.push(
            {
              unique_id: res[i].unique_id,
              file_name: res[i].file_name,
              reading_time: res[i].reading_time,
              complete_score: res[i].complete_score,
              selected: false
            }
          );
        }
      }
    },
    async deleteReadingResultList() {
      const res = await deleteReadingResult();
      if(res) {
        this.setReadingResultList();
      }
    },
    setShowNewIcon(show: boolean) {
      this.showNewIcon = show;
    }
  },
  getters: {
    getReadingResultList(state) {
      return state.readingResultList;
    },
    getShowNewIcon(state): boolean {
      return state.showNewIcon;
    }
  },
});
