
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { usePopupStore } from '@/store/popup'
import { useDicomStore } from '@/store/dicom'

export default defineComponent({
  components: {
  },
  props:{
  },
  setup(props, {emit}) {
    const router = useRouter();
    const popupStore = usePopupStore();
    const dicomStore = useDicomStore();

    const closePopup = () => {
      popupStore.togglePopup('logoutAlert', false);
    }

    const handleConfirmClick = () => {
      closePopup();
      dicomStore.initFileDataState();
      router.push({ name: 'login' });
    }

    onMounted(() => {
    });

    return {
      closePopup,
      handleConfirmClick
    };
  },
});
