import { defineStore } from 'pinia';

export const useTimerStore = defineStore('timer', {
  state: () => ({
    readingTime: 0,
    startTime: 0,
    endTime: 0,
  }),
  actions: {
    startTimer() {
      this.startTime = Date.now();
    },
    stopTimer() {
      this.endTime = Date.now();
      const durationInSeconds =  this.endTime - this.startTime;
      this.readingTime = parseFloat((durationInSeconds / 1000).toFixed(2));
    },
  },
  getters: {
    getStartTime(state): number {
      return state.startTime;
    },
    getEndTime(state): number {
      return state.endTime;
    },
    getReadingTime(state): number {
      return state.readingTime;
    }
  },
});