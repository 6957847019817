
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { usePopupStore } from '@/store/popup'
import { useDicomStore } from '@/store/dicom'

export default defineComponent({
  components: {
  },
  props:{
  },
  setup(props, {emit}) {
    const target = ref('origin'); // origin or prediction
    const format = ref('dcm'); // dcm or png

    const closePopup = () => {
      usePopupStore().togglePopup('export', false);
    }

    const handleFormatClick = async (): Promise<void> => {
      useDicomStore().handleExportFile(target.value, format.value);
      closePopup();
    }

    onMounted(() => {
    });

    return {
      closePopup,
      handleFormatClick,
      target,
      format
    };
  },
});
