
import { defineComponent, computed, onMounted, onUnmounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import { useLoginStore } from '@/store/login'
import { useUsersStore } from '@/store/users'

// export default defineComponent({
//   components: {
//     // NavBar
//   },
//   setup() {
//     // const localeStore = useLocaleStore();
//     // localeStore.getLanguageData();
//     // const checkLanguage = computed(() => localeStore.getCheckLanguage);

//     const handleBeforeUnload = async (event: BeforeUnloadEvent):Promise<any> => {
//       console.log('자동 로그아웃');
//       await useLoginStore().logout();
//       event.preventDefault();
//       event.returnValue = ''; // Chrome에서 동작하게 하기 위해 필요
//     }

//     onMounted(() => {
//       window.addEventListener('beforeunload', handleBeforeUnload);
//     });

//     // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
//     onBeforeUnmount(() => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     });

//     return {
//       // checkLanguage
//     };
//   },
// });

export default defineComponent({
  setup() {
    const router = useRouter();
    let isTabClosing = false;

    const handleBeforeUnload = async (event: BeforeUnloadEvent):Promise<any> => {
      if (isTabClosing) {
        console.log('창이 닫히고 있습니다. 로그아웃 처리 실행');
        await useLoginStore().logout();
        event.returnValue = ''; // Chrome에서 동작하게 하기 위해 필요
      } else {
        // 새로고침 시 경고 메시지 표시
        event.returnValue = '';
      }
    };

    const handleVisibilityChange = () => {
      // 페이지가 숨겨질 때(탭이 닫히거나 이동할 때)
      if (document.visibilityState === 'hidden') {
        isTabClosing = true;
      } else {
        isTabClosing = false;
      }
    };

    onMounted(() => {
      const token = useLoginStore().getAccessToken;
      console.log('token >> ', token);
      if(token == '' || token == undefined) {
        router.push({name: 'login'});
      }
      window.addEventListener('beforeunload', handleBeforeUnload);
      document.addEventListener('visibilitychange', handleVisibilityChange);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    });

    return {};
  },
});
