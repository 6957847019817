
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { checkRepetition, setUserData } from '@/api';
import { useUsersStore, JoinUser } from '@/store/users'
import { usePopupStore } from '@/store/popup'

export default defineComponent({
  components: {
  },
  props:{
  },
  setup(props, {emit}) {
    const router = useRouter();
    const inputData = ref<JoinUser>({
      "passwd": "",
      "data": {
        "user_id": "",
        "group": {
          "group_id": 1,
          "level": 1,
          "name": "사용자"
        },
        "name": "",
        "number": "",
        "email": "",
        "division": ""
      }
    });
    const duplicateCheck = ref();
    const passwordCheck = ref();
    const duplicateMessage = ref('');
    const passwordMessage = ref('');

    const setUser = async (): Promise<void> => {
      if(duplicateCheck.value && passwordCheck.value) {
        const res = await setUserData(inputData.value);
        if(res.result === "Success") {
          router.push({name: 'login'});
          usePopupStore().togglePopup('joinComplete', true);
        }
      } else {
        usePopupStore().togglePopup('joinFail', true);
      }
    }

    const duplicateConfirm = async (): Promise<void> => {
      if(inputData.value.data.user_id != '') {
        const res = await checkRepetition(inputData.value.data.user_id);
        duplicateCheck.value = res.result;
        if(res.result) {
          duplicateMessage.value = "* 사용 가능한 아이디입니다.";
        } else {
          duplicateMessage.value = "* 이미 사용중인 아이디입니다.";
        }
      } else {
        duplicateCheck.value = false;
        duplicateMessage.value = "* 아이디를 입력해주세요.";
      }
    }

    const validatePassword = () => {
      if(inputData.value.passwd != '') {
        // 영문자, 숫자, 특수문자 조합 및 최소 8자리 확인 정규식
        const regex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?`~-]).{8,}$/;
        if (!regex.test(inputData.value.passwd)) {
          passwordMessage.value = "* 영문자/숫자/특수문자 조합 8자리 이상이어야 합니다.";
          passwordCheck.value = false;
        } else {
          passwordCheck.value = true;
        }
      }
    }

    const movePage = () => {
      router.push({name: 'login'});
    }

    onMounted(() => {
      //
    })

    return {
      inputData,
      setUser,
      duplicateConfirm,
      duplicateMessage,
      passwordMessage,
      duplicateCheck,
      movePage,
      validatePassword
    };
  },
});
