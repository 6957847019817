
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { usePopupStore } from '@/store/popup'
import { useReadingResultStore } from '@/store/readingResult'

export default defineComponent({
  components: {
  },
  props:{
  },
  setup(props, {emit}) {
    const popupStore = usePopupStore();
    const readingResultStore = useReadingResultStore();

    const closePopup = () => {
      popupStore.togglePopup('deleteConfirm', false);
    }

    const handleConfirmClick = async (): Promise<void> => {
      closePopup();
      await readingResultStore.deleteReadingResultList();
    }

    onMounted(() => {
    });

    return {
      closePopup,
      handleConfirmClick
    };
  },
});
