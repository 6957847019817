
import { defineComponent, computed } from 'vue';
import Join from "../components/login/Join.vue";
import { usePopupStore } from '@/store/popup'
import JoinFailPopup from "../components/popup/JoinFailPopup.vue";

export default defineComponent({
  components: {
    Join,
    JoinFailPopup
  },
  setup() {
    const popupStore = usePopupStore();
    const joinFailPopupIsOpen = computed(() => popupStore.getIsOpen('joinFail'));
    
    return {
      joinFailPopupIsOpen
    };
  },
});
