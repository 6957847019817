
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { usePopupStore } from '@/store/popup'
import { useReadingResultStore } from '@/store/readingResult'

export default defineComponent({
  components: {
  },
  props:{
  },
  setup(props, {emit}) {
    const checkAll = ref(false);
    const popupStore = usePopupStore();
    const readingResultStore = useReadingResultStore();
    const readingResultList = computed(() => readingResultStore.getReadingResultList);

    const closePopup = () => {
      popupStore.togglePopup('resultList', false);
    }

    const selectAllList = (check: boolean) => {
      checkAll.value = check;

      for (let i = 0; i < readingResultList.value.length; i++) {
        readingResultList.value[i].selected = checkAll.value;
      }
    }

    const selectList = (list: any) => {
      list.selected = !list.selected;
      
      checkAll.value = true;
      for (let i = 0; i < readingResultList.value.length; i++) {
        if(!readingResultList.value[i].selected) {
          checkAll.value = false;
          break;
        }
      }
    }

    const deleteList = async (): Promise<void> => {
      popupStore.togglePopup('deleteConfirm', true);
    }

     watch(readingResultList, (newVal) => {
      checkAll.value = false;
    });

    onMounted(() => {
      selectAllList(false);
    });

    return {
      closePopup,
      checkAll,
      readingResultList,
      selectAllList,
      selectList,
      deleteList
    };
  },
});
